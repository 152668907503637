
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { Mutations } from "@/store/enums/StoreEnums";
import store from "@/store";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "buy-insurance-cart",
  components: {},
  data() {
    return {
      cartItems: [
        {
          publicId: "",
          dateUpdated: "",
          dateCreated: "",
          premium: "",
          package: "",
          invoiceNumber: "",
          endorsementType: "",
          hasBeenPaidFor: false,
        },
      ],
      totalPremium: "",
      cartIsEmpty: false,
      backgroundNumber: 3,
    };
  },
  setup() {
    const {
      isEmptyArray,
      reinitialization,
      useReusableNavigation,
    } = ResusableFunctions();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Cart", [
        "Insurance Packages",
        "Buy Insurance",
      ]);
    });

    return { isEmptyArray, reinitialization, useReusableNavigation };
  },
  created() {
    // Get authenticated user cart list
    this.getCartList();

    //Set page title
    document.title = "Cart | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getCartList() {
      //Fetch list authenticated user cart items
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.CART_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            if (this.isEmptyArray(data?.data?.items)) {
              this.cartIsEmpty = true;
              this.backgroundNumber = Math.floor(Math.random() * 4);
            }

            this.cartItems = data.data.items;
            this.totalPremium = data.data.totalAmount;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    deleteCartItem(publicId) {
      // Delete cart item
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.delete(`${variables.CART_ROUTE}/${publicId}`)
          .then(({ data }) => {
            this.getCartList();
            variables.toastAlert(data.data.message, "success");
            store.commit(Mutations.CART_MUTATION, data.data.cart);
            if (data.data.cart.count == 0) {
              this.$router.push({ name: "insurance-packages" });
            }
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
  },
});
